import { call, put, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router";

import { authenticatedClient } from "../../services/api-service";
import * as GroupsConstants from "../actions/constants/groups";
import store from "../stores/store";

const buildGroupSearchRequest = async (payload) => {
	const client = await authenticatedClient();

	return client
		.post("/groups/search", payload, {
			headers: {
				"Content-Type": "application/json",
			},
		})
		.then((response) => {
			if (response.status < 200 || response.status >= 300) {
				throw response;
			}
			return response;
		})
		.catch((error) => {
			if (error?.response?.status) {
				return error.response;
			}
			
			return error;
		});
}

const buildGroupLoadActiveRequest = async () => {
	const client = await authenticatedClient();

	return client
		.get("/groups/active", {
			headers: {
				"Content-Type": "application/json",
			},
		})
		.then((response) => {
			if (response.status < 200 || response.status >= 300) {
				throw response;
			}
			return response;
		})
		.catch((error) => {
			if (error?.response?.status) {
				return error.response;
			}
			
			return error;
		});
}

const buildGroupLoadRequest = async (groupId) => {
	const client = await authenticatedClient();

	return client
		.get(`/groups/${groupId}`, {
			headers: {
				"Content-Type": "application/json",
			},
		})
		.then((response) => {
			if (response.status < 200 || response.status >= 300) {
				throw response;
			}
			return response;
		})
		.catch((error) => {
			if (error?.response?.status) {
				return error.response;
			}
			
			return error;
		});
}

const buildGroupSaveRequest = async (groupData) => {
	if ((groupData?.groupId ?? 0) > 0) {
		return buildUpdateGroupRequest(groupData);
	} else {
		return buildNewGroupSaveRequest(groupData);
	}
}

const buildUpdateGroupRequest = async (groupData) => {
	const client = await authenticatedClient();

	return client
		.put(`/groups/${groupData.groupId}`,
			groupData,
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		)
		.then((response) => {
			if (response.status < 200 || response.status >= 300) {
				throw response;
			}
			return response;
		})
		.catch((error) => {
			if (error?.response?.status) {
				return error.response;
			}
			
			return error;
		});
}

const buildNewGroupSaveRequest = async (groupData) => {
	const client = await authenticatedClient();

	return client
		.post(`/groups`,
			groupData,
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		)
		.then((response) => {
			if (response.status < 200 || response.status >= 300) {
				throw response;
			}
			return response;
		})
		.catch((error) => {
			if (error?.response?.status) {
				return error.response;
			}
			
			return error;
		});
}

const buildDevicesReportRequest = async () => {
	const client = await authenticatedClient();

	return client
		.get("/devices/report", {
			headers: {
				"Content-Type": "application/excel",
			},
		})
		.then((response) => {
			if (response.status < 200 || response.status >= 300) {
				throw response;
			}
			
			return response;
		})
		.then((response) => new Blob([response.data], { type: 'application/excel' }))
		.then((blob) => {
			// Create blob link to download
			const url = window.URL.createObjectURL(
			  new Blob([blob]),
			);
			const link = document.createElement('a');
			link.href = url;

			const dtNow = new Date();
			link.setAttribute(
			  'download',
			  `report_${dtNow.getFullYear()}${(dtNow.getMonth() + 1).toString().padStart(2, "0")}${dtNow.getDate().toString().padStart(2, "0")}.csv`,
			);
		
			// Append to html link element page
			document.body.appendChild(link);
		
			// Start download
			link.click();
		
			// Clean up and remove the link
			link.parentNode.removeChild(link);

			return null;
		  })
		.catch((error) => {
			console.log('err', error);
			if (error?.response?.status) {
				return error.response;
			}
			
			return error;
		});
}

function* GroupSearchSaga(action) {
	try {
		let data = {
			searchTerm: action.searchTerm
		};
		
		const response = yield call(buildGroupSearchRequest, data);
		
		if (response?.errors) {
			throw response.errors[0].message;
		}

		if ((response?.status ?? 0) >= 300) {
			throw response.data;
		}
		
		//store.dispatch(push("/"));

		yield put({
			type: GroupsConstants.GROUP_SEARCH_SUCCEEDED,
			data: response.data,
			navigation: action.navigation,
		});
		
	} catch (error) {
		yield put({
			type: GroupsConstants.GROUP_SEARCH_FAILED,
			error: error,
		});
	}
}

function* GroupLoadActiveSaga(action) {
	try {
		const response = yield call(buildGroupLoadActiveRequest);
		
		if (response?.errors) {
			throw response.errors[0].message;
		}

		if ((response?.status ?? 0) >= 300) {
			throw response.data;
		}
		
		//store.dispatch(push("/"));

		yield put({
			type: GroupsConstants.GROUP_LOAD_ACTIVE_SUCCEEDED,
			data: response.data,
			navigation: action.navigation,
		});
		
	} catch (error) {
		yield put({
			type: GroupsConstants.GROUP_LOAD_ACTIVE_FAILED,
			error: error,
		});
	}
}

function* GroupLoadSaga(action) {
	try {
		const response = yield call(buildGroupLoadRequest, action.groupId);
		
		if (response?.errors) {
			throw response.errors[0].message;
		}

		if ((response?.status ?? 0) >= 300) {
			throw response.data;
		}
		
		//store.dispatch(push("/"));

		yield put({
			type: GroupsConstants.GROUP_LOAD_SUCCEEDED,
			data: response.data,
			navigation: action.navigation,
		});
		
	} catch (error) {
		yield put({
			type: GroupsConstants.GROUP_LOAD_FAILED,
			error: error,
		});
	}
}

function* GroupSaveSaga(action) {
	try {
		const response = yield call(buildGroupSaveRequest, action.groupData);
		
		if (response?.errors) {
			throw response.errors[0].message;
		}

		if ((response?.status ?? 0) >= 300) {
			throw response.data;
		}
		
		store.dispatch(push("/groups"));

		yield put({
			type: GroupsConstants.GROUP_SAVE_SUCCEEDED,
			navigation: action.navigation,
		});
		
	} catch (error) {
		yield put({
			type: GroupsConstants.GROUP_SAVE_FAILED,
			error: error,
		});
	}
}

function* DeviceReportSaga(action) {
	try {
		const response = yield call(buildDevicesReportRequest);
		
		if (response?.errors) {
			throw response.errors[0].message;
		}

		if ((response?.status ?? 0) >= 300) {
			throw response.data;
		}
		
		//store.dispatch(push("/"));

		// yield put({
		// 	type: GroupsConstants.,
		// 	data: response.data,
		// 	navigation: action.navigation,
		// });
		
	} catch (error) {
		// yield put({
		// 	type: GroupsConstants.GROUP_LOAD_FAILED,
		// 	error: error,
		// });
	}
}

export default function* groupsSagas() {
	//Take Latest
	yield takeLatest(GroupsConstants.GROUP_SEARCH_REQUESTED, GroupSearchSaga);
	yield takeLatest(GroupsConstants.GROUP_LOAD_REQUESTED, GroupLoadSaga);
	yield takeLatest(GroupsConstants.GROUP_SAVE_REQUESTED, GroupSaveSaga);
	yield takeLatest(GroupsConstants.GROUP_LOAD_ACTIVE_REQUESTED, GroupLoadActiveSaga);
	yield takeLatest(GroupsConstants.DOWNLOAD_DEVICES_REPORT_REQUESTED, DeviceReportSaga);
}
