import * as GroupsConstants from "../actions/constants/groups";

const intitalState = {
	isSearching: false,
	searchTerm: '',
	searchResults: [],
	isLoading: false,
	group: null,
	activeGroups: []
};

export const groups = (state = intitalState, action) => {
	switch (action.type) {
		case GroupsConstants.DOWNLOAD_DEVICES_REPORT_REQUESTED:
			return {
				...state,
				isSearching: true
			};
		// case GroupsConstants.DOWNLOAD_DEVICES_REPORT_SUCCEEDED:
		// 	return {
		// 		...state,
		// 		isSearching: false
		// 	};
		// case GroupsConstants.DOWNLOAD_DEVICES_REPORT_FAILED:
		// 	return {
		// 		...state,
		// 		isSearching: false
		// 	};
		case GroupsConstants.GROUP_SEARCH_REQUESTED:
			return {
				...state,
				isSearching: true
			};
		case GroupsConstants.GROUP_SEARCH_SUCCEEDED:
			return {
				...state,
				isSearching: false,
				searchTerm: '',
				searchResults: action.data.searchResults
			};
		case GroupsConstants.GROUP_SEARCH_FAILED:
			return {
				...state,
				isSearching: false,
				searchResults: []
			};
		case GroupsConstants.GROUP_LOAD_REQUESTED:
			return {
				...state,
				isLoading: true,
				group: null
			};
		case GroupsConstants.GROUP_LOAD_SUCCEEDED:
			return {
				...state,
				isLoading: false,
				group: action.data
			};
		case GroupsConstants.GROUP_LOAD_FAILED:
			return {
				...state,
				isLoading: false,
				group: null
			};
		case GroupsConstants.GROUP_SAVE_REQUESTED:
			return {
				...state,
				isLoading: true
			};
		case GroupsConstants.GROUP_SAVE_SUCCEEDED:
			return {
				...state,
				isLoading: false
			};
		case GroupsConstants.GROUP_SAVE_FAILED:
			return {
				...state,
				isLoading: false
			};
		case GroupsConstants.GROUP_LOAD_ACTIVE_REQUESTED:
			return {
				...state,
				isLoading: true,
				activeGroups: []
			};
		case GroupsConstants.GROUP_LOAD_ACTIVE_SUCCEEDED:
			return {
				...state,
				isLoading: false,
				activeGroups: action.data
			};
		case GroupsConstants.GROUP_LOAD_ACTIVE_FAILED:
			return {
				...state,
				isLoading: false,
				activeGroups: []
			};
		default:
			return { ...state };
	}
};
